import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

// Types
import type { MediaShowType, SettingsStore } from '@/types/helpers/store/settings'

// Configurations
import Config from '@/config'

// Settings Store
export const useSettingsStore = create(
	persist<SettingsStore>(
		set => ({
			menuOpen: true,
			menuOpenItems: {},
			mediaTree: true,
			mediaShowType: 'grid',
			setMenuOpen: (menuOpen: boolean) => set({ menuOpen }),
			setMenuOpenItems: (menuOpenItems: Record<string, boolean>) => set({ menuOpenItems }),
			setMediaTree: (mediaTree: boolean) => set({ mediaTree }),
			setMediaShowType: (mediaShowType: MediaShowType) => set({ mediaShowType })
		}),
		{
			name: `${Config.shortName}-settings`,
			storage: createJSONStorage(() => localStorage)
		}
	)
)
