// Types
import type { ApplicationProps } from '@/types/config'

const Config: ApplicationProps = {
	name: process.env.APP_NAME || 'Corleone Dashboard',
	shortName: process.env.APP_SHORT_NAME || 'corleone',
	siteURL: process.env.APP_SITE_URL || ''
}

export default Config
