'use client'

import { useState, useCallback, useEffect } from 'react'
import Cookies from 'js-cookie'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/navigation'
import { Box, AppBar, IconButton, Toolbar, Container, Grid } from '@mui/material'
import { MenuTwoTone as MenuTwoToneIcon, CancelTwoTone as CancelTwoToneIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

// Types
import type { APIUserCheckLoginReadResult, APIUserCheckLoginAdminReadResult } from '@/types/api/(user)/user/check-login'
import type { AppBarProps } from '@/types/components/templates/app-bar'

// Configurations
import Config from '@/config/'
import ThemeStyleConfig from '@/config/theme-style'

// Helpers
import { useSettingsStore } from '@/helpers/store/settings'
import { useUserStore } from '@/helpers/store/user'

// Components
const AvatarBarMolecule = dynamic(() => import('@/components/molecules/avatar-bar'))
const SwipeableDrawerMolecule = dynamic(() => import('@/components/molecules/drawer/swipeable'))
const PermanentDrawerMolecule = dynamic(() => import('@/components/molecules/drawer/permanent'))
const AsideMolecule = dynamic(() => import('@/components/molecules/aside'))
const DialogMolecule = dynamic(() => import('@/components/molecules/dialog'))
const TitleAtom = dynamic(() => import('@/components/atoms/shapes/title'))

const AppBarTemplate = (props: AppBarProps) => {
	// Props
	const { lng } = props

	// Variables
	const router = useRouter()
	const { enqueueSnackbar } = useSnackbar()
	const { menuOpen, setMenuOpen } = useSettingsStore()
	const { token, username, setToken, setAccessAdmin, setGroup, setUser_Name, setUsername, setEmail, setPicture, setLocale, reset } = useUserStore()
	const [menuOpenMobile, setMenuOpenMobile] = useState<boolean>(false)
	const [dialog, setDialog] = useState<boolean>(false)

	// Callbacks
	const checkToken = useCallback(async () => {
		// Response
		const responce = await fetch('/api/user/check-login', {
			headers: { 'Accept-Language': lng }
		})

		// Result
		const data: APIUserCheckLoginReadResult & APIUserCheckLoginAdminReadResult = await responce.json()

		// Check result
		if (data.status && data.data && data.data.item) {
			const item = data.data.item
			const user = item.user

			if (item && user) {
				// Update token
				const token = Cookies.get(`${Config.shortName}-token`)

				if (token) {
					setToken(token)
					Cookies.set(`${Config.shortName}-access-admin`, token, { expires: new Date(item.expires_date ?? '') })
				}

				// Update access admin
				setAccessAdmin(user.group?.access_admin ?? false)
				Cookies.set(`${Config.shortName}-access-admin`, user.group?.access_admin ? 'true' : 'false', { expires: new Date(item.expires_date ?? '') })

				// Update user details
				if (Object.hasOwn(user, 'group')) setGroup(user.group || null)
				if (Object.hasOwn(user, 'user_name')) setUser_Name(user.user_name)
				if (Object.hasOwn(user, 'username')) setUsername(user.username)
				if (Object.hasOwn(user, 'email')) setEmail(user.email)
				if (Object.hasOwn(user, 'picture')) setPicture(user.picture)
				if (Object.hasOwn(user, 'locale')) setLocale(user.locale)
			}
		}

		// Check re-login
		if (data.data && data.data.reLogin) setDialog(true)

		// Check messages
		if (data.message) enqueueSnackbar(data.message, { variant: data.status ? 'success' : 'error', autoHideDuration: 6000 })
		if (data.messages) data.messages.map(message => enqueueSnackbar(message, { variant: data.status ? 'success' : 'error', autoHideDuration: 6000 }))
	}, [lng, setToken, setAccessAdmin, setGroup, setUser_Name, setUsername, setEmail, setPicture, setLocale, enqueueSnackbar])

	useEffect(() => {
		if (token === '' && username === '') checkToken()
	}, [token, username, checkToken])

	return (
		<>
			<AppBar
				variant="outlined"
				elevation={0}
				color="inherit"
				sx={{
					bgcolor: 'transparent',
					right: { md: 'inherit' },
					px: 'inherit',
					borderWidth: 0,
					'&::before': {
						content: "''",
						width: 1,
						height: 1,
						position: 'absolute',
						top: 0,
						left: 0,
						backdropFilter: 'blur(20px)',
						zIndex: -1
					},
					...(menuOpenMobile && { zIndex: { xs: 'calc(var(--mui-zIndex-drawer) + 1)', md: 'inherit' } }),
					'& .MuiToolbar-root': {
						pl: { xs: 0, md: menuOpen ? ThemeStyleConfig.drawerWidth / 8 : 'calc(var(--mui-spacing) * 8)' },
						pr: 0
					}
				}}
			>
				<Toolbar>
					<Container maxWidth="xl">
						<Grid container spacing={1} alignItems="center">
							<Grid size="auto" display={{ md: 'none' }}>
								<IconButton
									edge="start"
									color="inherit"
									aria-label="menu"
									onClick={() => setMenuOpenMobile(!menuOpenMobile)}
									sx={{
										borderRadius: 1,
										'& .MuiTouchRipple-root .MuiTouchRipple-child': { borderRadius: 1 }
									}}
								>
									{menuOpenMobile ? <CancelTwoToneIcon /> : <MenuTwoToneIcon />}
								</IconButton>
							</Grid>

							<Grid size="grow">
								<TitleAtom lng={lng} />
							</Grid>

							<AvatarBarMolecule lng={lng} />
						</Grid>
					</Container>
				</Toolbar>
			</AppBar>

			<Box component="nav" flexShrink={{ md: 0 }} width={{ md: menuOpen ? ThemeStyleConfig.drawerWidth : 'calc(var(--mui-spacing) * 8)' }}>
				<SwipeableDrawerMolecule open={menuOpenMobile} setOpen={setMenuOpenMobile}>
					<AsideMolecule lng={lng} open={menuOpenMobile} />
				</SwipeableDrawerMolecule>

				<PermanentDrawerMolecule open={menuOpen} setOpen={setMenuOpen}>
					<AsideMolecule lng={lng} open={menuOpen} />
				</PermanentDrawerMolecule>
			</Box>

			<DialogMolecule
				lng={lng}
				title="common:title.reLogin"
				description="common:description.reLogin"
				confirm="common:title.reLogin"
				close="common:title.cancel"
				open={dialog}
				setOpen={setDialog}
				confirmAction={() => {
					// Remove cookies
					Cookies.remove(`${Config.shortName}-token`)
					Cookies.remove(`${Config.shortName}-access-admin`)

					// Reset user store
					reset()

					// Redirect
					router.push(`/${lng}/login`)
				}}
			/>
		</>
	)
}

export default AppBarTemplate
