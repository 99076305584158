import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

// Types
import type { UserStore } from '@/types/helpers/store/user'
import type { APIUserGroupItem } from '@/types/api/items/user/user-group'

// Configurations
import Config from '@/config'

// User Store
export const useUserStore = create(
	persist<UserStore>(
		set => ({
			token: '',
			accessAdmin: false,
			group: null,
			user_name: '',
			name: '',
			username: '',
			email: '',
			picture: '',
			locale: '',
			setToken: (token: string) => set({ token }),
			setAccessAdmin: (accessAdmin: boolean) => set({ accessAdmin }),
			setGroup: (group: APIUserGroupItem | null) => set({ group }),
			setUser_Name: (name: string) => set({ name }),
			setUsername: (username: string) => set({ username }),
			setEmail: (email: string) => set({ email }),
			setPicture: (picture: string) => set({ picture }),
			setLocale: (locale: string) => set({ locale }),
			reset: () => set({ token: '', accessAdmin: false, group: null, user_name: '', name: '', username: '', email: '', picture: '', locale: '' })
		}),
		{
			name: `${Config.shortName}-user`,
			storage: createJSONStorage(() => localStorage)
		}
	)
)
